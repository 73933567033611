import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Table, Divider, Grid, Segment, Header, Button, Form, Popup, Label } from 'semantic-ui-react';
import qs from 'qs';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Input from '../../components/Input';
import Page from '../../components/Page';
import Tag from '../../components/Tag';
import Loading from '../../components/ui/Loading';

import EntityItem from '../Entity/Item';

import '../../assets/css/ui/MessageEntities.module.scss';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const MESSAGE_ENTITIES_QUERY = gql`
  query MessageEntitiesQuery(
    $id: ID!,
    $limit: Int,
    $offset: Int,
    $search: String,
    $status: MessageRecipientsStatus,
    $strictStatus: Boolean
  ) {
      node(id: $id) @connection(key: "Message", filter: ["id"]) {
        ... on Message {
          id: dbId
          recipients (limit: $limit, offset: $offset, entityFullname: $search, status: $status, strictStatus: $strictStatus) {
            nodes {
              application {
                name
              }
              status
              read
              entity {
                id: dbId
                fullname
                organizationId
                roles (limit: 40) {
                  nodes {
                    name
                  }
                }
                ...EntityItemEntity
              }
              user {
                id: dbId
                fullname
              }
            }
            totalCount
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
          }
          sent: recipientsCount
          sentOnly: recipients (status: SENT, strictStatus: true) {
            totalCount
          }
          received: recipients (status: RECEIVED) {
            totalCount
          }
          receivedOnly: recipients (status: RECEIVED, strictStatus: true) {
            totalCount
          }
          read: recipients (status: READ) {
            totalCount
          }
          replied: conversations {
            totalCount
          }
        }
      }
    }
    ${EntityItem.fragments.entity}
`;

const statusIcon = (status, applicationName) => {
  if (status === 0) {
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check" />
        <span>{__('Sent')}</span>
      </div>
    );
  } else if (status === 1) {
    return (
      <div style={{ display: 'flex' }}>
        <Icon color="black" name="check double" />
        <span>{__('Received')}</span>
      </div>
    );
  } else if (status === 2) {
    const userReadBy = applicationName && (applicationName === 'ClassApp Web' || applicationName === 'ClassApp iOS' || applicationName === 'ClassApp Android') ? 'ClassApp' : 'Email';
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check double" style={{ color: '#0080FF' }} />
        <span>{__('Read')}</span>
        {applicationName ? <span style={{ marginLeft: '3px', opacity: '.5', fontWeight: 'bold' }}>({userReadBy})</span> : null}
      </div>
    );
  } else if (status === 3 && !applicationName) {
    return (
      <div style={{ display: 'flex' }}>
        <Icon color="black" name="check double" />
        <span>{__('Received')}</span>
      </div>
    );
  } else if (status === 3 && applicationName) {
    const userReadBy = applicationName && (applicationName === 'ClassApp Web' || applicationName === 'ClassApp iOS' || applicationName === 'ClassApp Android') ? 'ClassApp' : 'Email';
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check double" style={{ color: '#0080FF' }} />
        <span>{__('Read')}</span>
        {applicationName ? <span style={{ marginLeft: '3px', opacity: '.5', fontWeight: 'bold' }}>({userReadBy})</span> : null}
      </div>
    );
  } else if (status === 4 && !applicationName) {
    return (
      <div style={{ display: 'flex' }}>
        <Icon color="black" name="check double" />
        <span>{__('Received')}</span>
      </div>
    );
  } else if (status === 4 && applicationName) {
    const userReadBy = applicationName && (applicationName === 'ClassApp Web' || applicationName === 'ClassApp iOS' || applicationName === 'ClassApp Android') ? 'ClassApp' : 'Email';
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="check double" style={{ color: '#0080FF' }} />
        <span>{__('Read')}</span>
        {applicationName ? <span style={{ marginLeft: '3px', opacity: '.5', fontWeight: 'bold' }}>({userReadBy})</span> : null}
      </div>
    );
  }
  return null;
};

const statusOptions = () => [
  { text: __('Read'), value: 'READ' },
  { text: __('Received Only'), value: 'RECEIVED' },
  { text: __('Sent Only'), value: 'SENT' }
];

const PAGE_SIZE = 40;

@inject('store', 'client')
@observer
export default class MessageEntities extends Page {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        offset: parseInt(((props.location.query.p || 1) - 1) * (props.location.query.limit || PAGE_SIZE), 10),
        search: props.location.query.search || '',
        status: props.location.query.status || null
      },
      data: null,
      loading: true,
      loadingTable: false
    };
  }

  async componentDidMount() {
    const { message_id } = this.props.params;
    const result = await this.props.client.query({
      query: MESSAGE_ENTITIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id: message_id,
        limit: PAGE_SIZE,
        offset: 0,
        search: '',
        status: null,
        strictStatus: true
      }
    });

    this.setState({ loading: false, data: result.data });
  }

  fetchMore = async () => {
    this.setState({ loadingTable: true });
    const { query } = this.state;
    const { message_id } = this.props.params;
    const { search, status } = query;
    const data = { ...this.state.data };
    const offset = data.node.recipients.nodes.length;

    const result = await this.props.client.query({
      query: MESSAGE_ENTITIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id: message_id,
        limit: PAGE_SIZE,
        offset,
        search,
        status,
        strictStatus: true
      }
    });

    data.node.recipients.nodes = [...data.node.recipients.nodes, ...result.data.node.recipients.nodes];
    data.node.recipients.pageInfo = result.data.node.recipients.pageInfo;

    this.setState({ data, loadingTable: false });
  }

  onUniqueParameterChange = (name, value, type) => {
    const parameters = { ...this.state.query };
    if (type === 'array' && value.length <= 1) {
      parameters[name] = value[0];
    } else if (type === 'array') {
      parameters[name] = value.filter(o => parameters[name].indexOf(o) === -1)[0];
    } else if (value.length) {
      parameters[name] = value;
    } else {
      parameters[name] = '';
    }
    this.eventReload(parameters);
  }

  eventReload = async (query = {}) => {
    this.setState({ loadingTable: true, query });

    const { message_id } = this.props.params;
    const { offset, search, status } = query;

    const result = await this.props.client.query({
      query: MESSAGE_ENTITIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id: message_id,
        limit: PAGE_SIZE,
        offset,
        search,
        status,
        strictStatus: true
      }
    });

    this.setState({ loadingTable: false, data: result.data });
  }

  renderInfo = (info, key) =>
    <Grid.Column key={key} style={info.style}>
      <Segment style={{ padding: '20px 0px', textAlign: 'center' }}>
        <div>
          <Header style={{ margin: 0 }} as="h5" disabled>
            {info.name}
          </Header>
          <div>
            {info.icon}
            <Header style={{ margin: 0, display: '-webkit-inline-box' }} as="h3">
              <Header.Content>
                {info.summary}
              </Header.Content>
            </Header>
          </div>
        </div>
      </Segment>
    </Grid.Column>

  renderInfoMobile = (info, key) => {
    const { query } = this.state;
    const { store } = this.props;
    return (
      <div
        key={key}
        className="mobile-item"
        onClick={() => (store.currentEntity.type !== 'STUDENT' ? this.onUniqueParameterChange('status', [info.value], 'array') : null)}
        style={{ border: store.currentEntity.type !== 'STUDENT' && query && query.status === info.value ? '1px solid rgba(8, 79, 255, 1)' : '1px solid rgba(0, 0, 0, 0.06)' }}
      >
        <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}>
          {info.name}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{info.icon}</div>
          <div style={{ fontWeight: 'bold' }}>{info.summary}</div>
        </div>
      </div>);
  };

  renderGroup = (name, key) => {
    if (name.length <= 10) return <span key={key || 0}>{name}</span>;

    return (
      <Popup
        basic
        hideOnScroll
        key={key || 0}
        content={<div>{name}</div>}
        trigger={<span>{name.substring(0, 10).trim()}...</span>}
      />
    );
  }

  renderMap = (loading) => {
    const { store } = this.props;
    const { data } = this.state;
    const { recipients } = data.node;
    const { nodes } = recipients;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    if (loading) {
      return (
        <div>
          <Loading width={35} height={35} />
        </div>
      );
    }

    return nodes.map(recipient =>
      <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.08)', borderTop: store.currentEntity.type !== 'STUDENT' ? '0px' : '1px solid rgba(0, 0, 0, 0.08)', padding: '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <EntityItem
            entity={filter(EntityItem.fragments.entity, recipient.entity)}
            limit
            wrapType
            nameStyle={recipient.entity.fullname && recipient.entity.fullname.length > 20 && { width: '70%' }}
            subTitle={(recipient.user && recipient.user.fullname) || ' '}
            to={store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('READ_ENTITY'))) ? `/organizations/${recipient.entity.organizationId}/${recipient.entity.isChannel ? 'channels' : 'entities'}?search=${recipient.entity.fullname}` : null}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {statusIcon(recipient.status)}
            </div>
            <div>
              <span style={{ opacity: '.45' }}>
                {recipient.read ? utils.simpleDate(recipient.read, true, __('DD/MM - LT'), lang).replace('-', '·') : ''}
              </span>
            </div>
          </div>
        </div>
        {recipient.entity.roles.nodes.length > 0 &&
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', marginRight: '8px' }}>{__('Groups:')}</div>
            <div style={{ display: 'flex' }}>
              <div className="tag">{recipient.entity.roles.nodes.length > 0 && (recipient.entity.roles.nodes[0].name.length > 16 ? (recipient.entity.roles.nodes[0].name.substring(0, 16) + '...') : recipient.entity.roles.nodes[0].name)
              }</div>
              {recipient.entity.roles.nodes.length > 1 &&
                <Popup
                  trigger={<div style={{ marginLeft: '8px' }} className="tag">{`+${' '}${recipient.entity.roles.nodes.length - 1}`}</div>}
                  content={<span>{recipient.entity.roles.nodes.map((g, i) => (i == recipient.entity.roles.nodes.length - 1 ? `${g.name}` : `${g.name}, `))}</span>}
                />
              }
            </div>
          </div>
        }
      </div>);
  }

  renderTable = (loading) => {
    const { store } = this.props;
    const { query, data } = this.state;
    const { recipients } = data.node;
    const { nodes } = recipients;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    return (
      <div>
        <Table>
          <Table.Header style={{ minHeight: '65px' }}>
            <Table.Row>
              <Table.HeaderCell collapsing>
                {
                  store.currentEntity.type !== 'STUDENT' ?
                    <Form.Field
                      control={Input}
                      wait
                      icon="search"
                      placeholder={__('Recipient') + '...'}
                      onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
                    />
                    :
                    __('Recipient')
                }
              </Table.HeaderCell>
              <Table.HeaderCell collapsing>{__('User')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{__('Groups')}</Table.HeaderCell>
              <Table.HeaderCell collapsing style={{ textAlign: '-webkit-center' }}>
                {
                  store.currentEntity.type !== 'STUDENT' ?
                    <Form.Dropdown
                      fluid
                      multiple
                      selection
                      style={{ minWidth: '140px' }}
                      placeholder={__('Status')}
                      value={query.status ? [query.status] : []}
                      options={statusOptions()}
                      onChange={(e, { value }) => this.onUniqueParameterChange('status', value, 'array')}
                    />
                    :
                    __('Status')
                }
              </Table.HeaderCell>
              <Table.HeaderCell collapsing />
            </Table.Row>
          </Table.Header>
          {!loading &&
            <Table.Body>
              {
                nodes.map(recipient =>
                  <Table.Row key={recipient.entity.id}>
                    <Table.Cell key={0}>
                      <EntityItem
                        entity={filter(EntityItem.fragments.entity, recipient.entity)}
                        limit
                        to={store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('READ_ENTITY'))) ? `/organizations/${recipient.entity.organizationId}/${recipient.entity.isChannel ? 'channels' : 'entities'}?search=${recipient.entity.fullname}` : null}
                      />
                    </Table.Cell>
                    <Table.Cell key={1}>
                      {recipient && recipient.user && recipient.user.fullname}
                    </Table.Cell>
                    <Table.Cell key={2} style={{ whiteSpace: 'nowrap' }}>
                      {
                        recipient.entity.roles.nodes.length ?
                          (
                            recipient.entity.roles.nodes.length === 1 ?
                              recipient.entity.roles.nodes.map((role, key) => this.renderGroup(role.name, key))
                              :
                              [
                                this.renderGroup(recipient.entity.roles.nodes[0].name),
                                <Popup
                                  basic
                                  on="click"
                                  key={1}
                                  content={<div>{recipient.entity.roles.nodes.slice(1).map(role => <div style={{ padding: '5px' }}>{role.name}</div>)}</div>}
                                  trigger={<Label circular as={Button} style={{ marginLeft: '10px' }}>+{recipient.entity.roles.nodes.length - 1}</Label>}
                                />
                              ]
                          )
                          :
                          null
                      }
                    </Table.Cell>
                    <Table.Cell key={3} textAlign="center">
                      {statusIcon(recipient.status, (recipient.application && recipient.application.name) || null)}
                    </Table.Cell>
                    <Table.Cell key={4} style={{ whiteSpace: 'nowrap' }}>
                      <span style={{ opacity: '.45' }}>
                        {recipient.read ? utils.simpleDate(recipient.read, true, __('MMMM D - LT'), lang).replace('-', '·') : ''}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>}
        </Table>
        {loading && <Loading width={35} height={35} />}
      </div>
    );
  };

  render() {
    const { store, params, router } = this.props;
    const { query, data, loading, loadingTable } = this.state;

    if (!data || loading) {
      return (
        <Modal id="MessageEntities" open size="large" closeIcon="times close inside" onClose={() => router.goBack()}>
          <Modal.Header>{__('Status')}</Modal.Header>
          <Modal.Content style={this.isMobile() ? { paddingLeft: '0px !important', paddingRight: '0px !important' } : { padding: '0 0 5rem 0' }}>
            <Loading width={30} height={30} />
          </Modal.Content>
        </Modal>
      );
    }

    const { recipients, sent, sentOnly, received, receivedOnly, read, replied } = data.node;
    const { pageInfo } = recipients;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const exportLink = query.status ? `${store.app.url}/csv/message/entities?` +
      `message_id=${params.message_id}&${qs.stringify(query)}&access_token=${encodeURIComponent(store.access_token)}&limit=${sent}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${encodeURIComponent(lang)}` : `${store.app.url}/csv/message/entities?` +
    `message_id=${params.message_id}&offset=${query.offset}&search=${query.search}&access_token=${encodeURIComponent(store.access_token)}&limit=${sent}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${encodeURIComponent(lang)}`;
    const infosWeb = [
      { name: __('Sent'), summary: sent, icon: <Icon name="check" />, style: { padding: '21px 10px 21px 0px' } },
      { name: __('Received'), summary: received.totalCount, icon: <Icon color="black" name="check double" />, style: { padding: '21px 0px 21px 10px' } },
      { name: __('Read'), summary: read.totalCount, icon: <Icon name="check double" style={{ color: '#0080FF' }} />, style: { padding: '21px 0px 21px 10px' } },
      { name: __('Replied'), summary: replied.totalCount, icon: <Icon name="reply" />, style: { padding: '21px 0px 21px 10px' } }
    ];

    const infosMobile = [
      { name: __('All'), value: null, summary: sent, icon: <Icon name="paper plane" /> },
      { name: __('Sent Only'), value: 'SENT', summary: sentOnly.totalCount, icon: <Icon name="check" /> },
      { name: __('Received Only'), value: 'RECEIVED', summary: receivedOnly.totalCount, icon: <Icon color="black" name="check double" /> },
      { name: __('Read'), value: 'READ', summary: read.totalCount, icon: <Icon name="check double" style={{ color: '#0080FF' }} /> },
    ];

    return (
      <Modal id="MessageEntities" open size="large" closeIcon="times close inside" onClose={() => router.goBack()}>
        <Modal.Header>{__('Status')}</Modal.Header>
        <Modal.Content style={this.isMobile() ? { paddingLeft: '0px !important', paddingRight: '0px !important' } : {}}>
          {
            store.currentEntity.type !== 'STUDENT' && !this.isMobile() &&
            <Grid container columns={4} style={{ padding: 0 }}>
              {infosWeb.map((info, key) => this.renderInfo(info, key))}
            </Grid>
          }
          {
            store.currentEntity.type !== 'STUDENT' && this.isMobile() &&
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              {infosMobile.map((info, key) => this.renderInfoMobile(info, key))}
            </div>
          }
          <Divider fitted hidden />
          {
            this.isMobile() && store.currentEntity.type !== 'STUDENT' &&
            <Form.Field
              className={'mobile-search'}
              control={Input}
              wait
              icon="search"
              placeholder={__('Recipient') + '...'}
              onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
            />
          }
          {this.isMobile() ? this.renderMap(loadingTable) : this.renderTable(loadingTable)}
          <Pagination
            pagination={{
              graphql: true,
              hasNextPage: pageInfo && pageInfo.hasNextPage,
              loading: loadingTable
            }}
            onLoadMore={() => this.fetchMore()}
          />
          {this.props.children}
        </Modal.Content>
        {
          store.currentEntity.type !== 'STUDENT' &&
          <Modal.Actions>
            <Button
              icon="cloud download"
              content={__('Export selected data')}
              as="a"
              href={exportLink}
            />
          </Modal.Actions>
        }
      </Modal>
    );
  }
}
