import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { List, Icon, Label, Header, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { __ } from '../../../i18n';
import * as utils from '../../../utils';

import ApproveItem from '../ApproveItem';
import Avatar from '../../../components/Avatar';
import Responsive from '../../../components/Responsive';
import ColoredCheckbox from '../../../components/ColoredCheckbox';
import ReviewedBy from '../../../components/ui/ReviewedBy';

import { ReceivedSection } from './components/ReceivedSection';
import { isNetworkChild } from '../../../app/helpers/isNetworkChild';

const styles = {
  corner: {
    position: 'absolute',
    bottom: '-4px',
    right: '-4px',
    backgroundColor: '#ffffff',
    borderRadius: '1em',
  },
  defaultTextColor: {
    color: '#000000',
    opacity: '.8',
    display: 'flex',
  },
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '36px',
    height: '36px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typeIcon: {
    color: '#9696a0',
    marginRight: '0.4285712rem',
  },
  deletedMessage: {
    display: 'flex',
    width: 36,
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    opacity: 0.65,
  },
};

@inject('store')
@observer
export default class MessageItem extends Responsive {
  static fragments = {
    entityMessage: gql`
      fragment MessageItemEntity on Message {
        id: dbId
        unread
        changed
        created
        deleted
        summary
        type
        recipientsCount
        fwMessageId
        pin
        forum
        public
        status
        imagesCount
        videosCount
        audiosCount
        filesCount
        reportsCount
        surveysCount
        commitmentsCount
        chargesCount
        formsCount
        signaturesCount
        notifications {
          totalCount
        }
        entity {
          id: dbId
          fullname
          disabled
          picture {
            uri
            id: dbId
            key
          }
        }
        user {
          id: dbId
          fullname
        }
        toEntity {
          id: dbId
          fullname
          status
          picture {
            uri
            id: dbId
            key
          }
        }
        label {
          id: dbId
          title
          color
        }
      }
    `,
    entityDeletedMessage: gql`
      fragment MessageDeletedItemEntity on Message {
        id: dbId
        statuses(status: DELETED) {
          nodes {
            id: dbId
            entityId
            user {
              id: dbId
              fullname
            }
            created
          }
        }
        unread
        changed
        created
        deleted
        summary
        type
        recipientsCount
        fwMessageId
        pin
        forum
        public
        status
        imagesCount
        videosCount
        audiosCount
        filesCount
        reportsCount
        surveysCount
        commitmentsCount
        chargesCount
        formsCount
        signaturesCount
        notifications {
          totalCount
        }
        entity {
          id: dbId
          fullname
          disabled
          picture {
            uri
            id: dbId
            key
          }
        }
        user {
          id: dbId
          fullname
        }
        toEntity {
          id: dbId
          fullname
          status
          picture {
            uri
            id: dbId
            key
          }
        }
        label {
          id: dbId
          title
          color
        }
      }
    `,
    organizationMessage: gql`
      fragment MessageItemOrganization on Message {
        id: dbId
        unread
        changed
        created
        deleted
        summary
        type
        recipientsCount
        fwMessageId
        pin
        forum
        public
        imagesCount
        videosCount
        audiosCount
        filesCount
        reportsCount
        surveysCount
        commitmentsCount
        chargesCount
        formsCount
        signaturesCount
        entity {
          id: dbId
          fullname
          disabled
          picture {
            uri
            id: dbId
            key
          }
        }
        user {
          id: dbId
          fullname
        }
        toEntity {
          id: dbId
          fullname
          status
          picture {
            uri
            id: dbId
            key
          }
        }
        label {
          id: dbId
          title
          color
        }
      }
    `,
  };

  convertToRegExp(str) {
    const treatedStr = str
      .replace(/([.^$*+\-?()[\]{}\\|/])/g, '\\$1') // Escape RegExp special characters: . ^ $ * + - ? ( ) [ ] { } \ | — /
      .replace(/c|ç/gi, '[cç]')
      .replace(/a|á|à|ã|ä/gi, '[aáàãä]')
      .replace(/e|é|è|ë/gi, '[eéèë]')
      .replace(/i|í|ì|ï/gi, '[iíìï]')
      .replace(/o|ó|ò|õ|ö/gi, '[oóòõö]')
      .replace(/u|ú|ù|ü/gi, '[uúùü]')
      .replace(/n|ñ/gi, '[nñ]');
    return new RegExp(treatedStr, 'gi');
  }

  highlightSearch(str, searchStr) {
    if (searchStr && searchStr.trim().length > 0) {
      // Checks if the whole searchStr is present on str
      const searchStrRegExp = this.convertToRegExp(searchStr);
      const matches = str.match(searchStrRegExp);
      const trimmedSearchStrRegExp = this.convertToRegExp(searchStr.trim());
      const trimmedMatches = str.match(trimmedSearchStrRegExp);
      let highlighted = [];

      if (matches) {
        // highlight when there's a match
        str.split(searchStrRegExp).forEach((subStr, i, splittedStr) => {
          if (subStr) highlighted.push(subStr);
          if (i < splittedStr.length - 1) {
            highlighted.push(
              <span style={{ backgroundColor: '#F3E3A5' }}>{matches[i]}</span>,
            );
          }
        });
      } else if (trimmedMatches) {
        str.split(trimmedSearchStrRegExp).forEach((subStr, i, splittedStr) => {
          if (subStr) highlighted.push(subStr);
          if (i < splittedStr.length - 1) {
            highlighted.push(
              <span style={{ backgroundColor: '#F3E3A5' }}>
                {trimmedMatches[i]}
              </span>,
            );
          }
        });
      } else highlighted.push(str); // keep the original string when there isn't a match

      // Split the searchStr in individual keywords
      const searchKeywords = searchStr
        .trim()
        .split(' ')
        .sort((a, b) => b.length - a.length);
      if (searchKeywords.length === 1) return highlighted; // If there's only one keyword, searchStr === keyword. So, no need to highlight again

      searchKeywords.forEach((keyWord) => {
        highlighted = highlighted
          .map((item) => {
            if (typeof item !== 'string') return item;
            return this.highlightSearch(item, keyWord);
          })
          .flat()
          .filter((item) => item !== '');
      });
      return highlighted;
    }
    return str;
  }

  renderInfo() {
    const { message } = this.props;
    const replies_count =
      message.type === 'SENT' && message.replies && message.replies.totalCount;

    return (
      <span>
        <span className="message-status-icons-list">
          {!!replies_count && replies_count >= 1 && (
            <span
              style={
                message.deleted
                  ? { ...styles.defaultTextColor, opacity: 0.65 }
                  : { ...styles.defaultTextColor, opacity: 0.65 }
              }
            >
              <span>{replies_count}</span>
              <Icon
                style={
                  message.deleted
                    ? { marginLeft: '4px', opacity: 0.65 }
                    : { marginLeft: '4px' }
                }
                data-name="reply"
                color="grey"
                name="reply"
              />
            </span>
          )}
          {!!message.pin && (
            <Icon
              data-name="pin"
              color="grey"
              name="star"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.forum && (
            <Icon
              data-name="forum"
              color="grey"
              name="comments"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.filesCount && (
            <Icon
              data-name="file"
              color="grey"
              name="file"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.commitmentsCount && (
            <Icon
              data-name="commiment"
              color="grey"
              name="calendar"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.imagesCount && (
            <Icon
              data-name="image"
              className="semantic_icon"
              color="grey"
              name="image"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.videosCount && (
            <Icon
              data-name="video"
              color="grey"
              name="video"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.audiosCount && (
            <Icon
              data-name="audio"
              color="grey"
              name="volume up"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.surveysCount && (
            <Icon
              data-name="survey"
              color="grey"
              name="bar chart"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {!!message.reportsCount && (
            <Icon
              data-name="report"
              color="grey"
              name="list"
              style={
                message.deleted
                  ? { padding: '0px', opacity: 0.65 }
                  : { padding: '0px' }
              }
            />
          )}
          {!!message.chargesCount && (
            <Icon data-name="public" color="grey" name="donate" />
          )}
          {!!message.formsCount && (
            <Icon data-name="form" color="grey" name="poll h" />
          )}
          {!!message.signaturesCount && (
            <Icon data-name="signature" color="grey" name="file signature" />
          )}
          {!!message.public && (
            <Icon
              data-name="public"
              color="grey"
              name="globe"
              style={message.deleted ? { opacity: 0.65 } : null}
            />
          )}
          {message.unread > 0 && (
            <Label
              data-name="unread"
              circular
              size="tiny"
              color="blue"
              style={
                message.deleted ? { opacity: 0.65, fontStyle: 'italic' } : null
              }
            >
              {message.unread}
            </Label>
          )}
        </span>
      </span>
    );
  }

  renderReceived() {
    const { message, store } = this.props;

    return (
      <ReceivedSection
        isNetworkChild={isNetworkChild(store.currentOrganization)}
        message={message}
      />
    );
  }

  renderSent() {
    const { recipientsCount, toEntity } = this.props.message;

    return (
      <div
        style={
          this.props.message.deleted
            ? { opacity: 0.65 }
            : { ...styles.defaultTextColor }
        }
      >
        <Icon
          data-name="users"
          name="paper plane"
          style={
            this.props.message.deleted
              ? { opacity: 0.65 }
              : { ...styles.typeIcon }
          }
        />
        <span
          data-name="recipients"
          data-value={`recipients-count-${recipientsCount || ''}`}
          style={
            this.isMobile()
              ? { color: '#999999' }
              : this.props.message.deleted
                ? { opacity: 0.65 }
                : null
          }
        >
          {recipientsCount === 1
            ? [
              toEntity && toEntity.fullname,
              <Icon.Group>
                {toEntity && toEntity.status === 0 && (
                  <Icon.Group
                    style={{
                      marginLeft: '4px',
                      opacity: this.props.message.deleted ? 0.65 : null,
                    }}
                  >
                    <Icon name="check" />
                  </Icon.Group>
                )}
                {toEntity && toEntity.status > 0 && toEntity.status < 1 && (
                  <Icon.Group
                    style={{
                      marginLeft: '4px',
                      opacity: this.props.message.deleted ? 0.65 : null,
                    }}
                  >
                    <Icon color="black" name="check double" />
                  </Icon.Group>
                )}
                {toEntity && toEntity.status > 1 && (
                  <Icon.Group
                    style={{
                      marginLeft: '4px',
                      opacity: this.props.message.deleted ? 0.65 : null,
                    }}
                  >
                    <Icon name="check double" style={{ color: '#0080FF' }} />
                  </Icon.Group>
                )}
              </Icon.Group>,
            ]
            : __('%s recipients', String(recipientsCount))}
        </span>
      </div>
    );
  }

  renderDeletedFor() {
    const { message, userHasEntity, store } = this.props;
    const users = message.statuses.nodes;

    if (userHasEntity) {
      return (
        <span style={{ color: '#999999' }}>
          <Icon name="trash" style={{ margin: '0 0.25rem 0 0' }} />
          {__(
            'Deleted on %s',
            utils
              .simpleDate(
                users[0].created,
                false,
                ['ja', 'zh', 'zh-cn'].includes(store.app.locale) ? 'LL' : 'L',
                store.app.locale,
              )
              .toLowerCase(),
          )}
        </span>
      );
    }

    const onClick = (e) => {
      e.preventDefault();
    };

    if (users.length === 1) {
      return (
        <span style={{ color: '#999999' }}>
          <Icon name="trash" style={{ margin: '0 0.25rem 0 0' }} />
          {utils.parseFormattedTranslation({
            text: `${__('Deleted for **%s**', users[0].user.fullname)}`,
            Wrapper: ({ children }) => (
              <span style={{ fontWeight: 'bold' }}>
                {utils.renderLongText(children)}
              </span>
            ),
          })}
        </span>
      );
    }

    return (
      <span style={{ color: '#999999' }}>
        <Icon name="trash" style={{ margin: '0 0.25rem 0 0' }} />
        {utils.parseFormattedTranslation({
          text: __('Deleted for **%s users**', users.length),
          Wrapper: ({ children }) => (
            <Popup
              basic
              className="deletedForUserList"
              trigger={
                <span
                  style={{
                    color: '#999999',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                  className="lighten-on-hover"
                  onClick={onClick}
                >
                  {children}
                </span>
              }
              on="click"
              header={<div className="userListHeader">{__('Deleted for')}</div>}
              content={
                <ul className="userList">
                  {users.map(({ created, user, id }) => (
                    <li key={id}>
                      <span>{utils.renderLongText(user.fullname, 13)}</span>{' '}
                      <time>
                        {__(
                          'on %s',
                          utils
                            .simpleDate(created, false, 'LLL', store.app.locale)
                            .toLowerCase(),
                        )}{' '}
                      </time>
                    </li>
                  ))}
                </ul>
              }
              position="bottom left"
              wide="very"
            />
          ),
        })}
      </span>
    );
  }

  renderToogleMessage = () => {
    const { message, onToggle, disabled, hasPopup } = this.props;

    return (
      <div
        style={
          message.deleted
            ? styles.deletedMessage
            : {
              display: 'flex',
              width: 36,
              alignItems: 'center',
              backgroundColor: '#FFFFFF',
            }
        }
      >
        {hasPopup ? (
          <Popup
            trigger={
              <ColoredCheckbox
                data-action="select-message"
                data-params={`${message.id}`}
                name="message"
                checked={!!message.selected}
                onClick={onToggle}
                disabled={disabled}
                style={{ margin: 'auto' }}
              />
            }
            on="hover"
            content={__('Sorry, only 100 messages can be edited each time')}
            inverted
            position="right center"
            wide="very"
          />
        ) : (
          <ColoredCheckbox
            data-action="select-message"
            data-params={`${message.id}`}
            name="message"
            checked={!!message.selected}
            onClick={onToggle}
            disabled={disabled}
            style={{ margin: 'auto' }}
          />
        )}
      </div>
    );
  };

  getReducedTitle = (title) => {
    const innerWidth = window && window.innerWidth;
    if (innerWidth > 435) return title.replace(/^(.{18}).+/, '$1...');
    if (innerWidth > 400) return title.replace(/^(.{15}).+/, '$1...');
    if (innerWidth > 380) return title.replace(/^(.{14}).+/, '$1...');
    if (innerWidth > 370) return title.replace(/^(.{13}).+/, '$1...');
    return title.replace(/^(.{11}).+/, '$1...');
  };

  renderMessageTitle = (title, search) => {
    if (!title) return __('No Subject');

    if (this.isMobile()) {
      const reducedTitle = this.getReducedTitle(title);

      return this.highlightSearch(reducedTitle, search || '');
    }

    return title.length > 45
      ? this.highlightSearch(title.replace(/^(.{43}).+/, '$1...'), search || '')
      : this.highlightSearch(title, search || '');
  };

  render() {
    const {
      message,
      onClick,
      store,
      to,
      onToggle,
      organization,
      search,
      deleted,
      isApproval,
      handleReprove,
      handleApprove,
      goToEdit,
      isMessageToApprove = false,
    } = this.props;

    const className =
      message.unread !== null ||
      (message.notifications && message.notifications.totalCount > 0) ||
      isApproval
        ? undefined
        : 'active';
    const date = utils.simpleDate(
      message.changed || message.created,
      true,
      null,
      store.app.locale,
    ); //date[0] = month, date[1]=day: necessary for translation
    const params = {};
    const statusIcons =
      !!message.pin +
      !!message.forum +
      !!message.filesCount +
      !!message.commitmentsCount +
      !!message.imagesCount +
      !!message.videosCount +
      !!message.audiosCount +
      !!message.surveysCount +
      !!message.reportsCount +
      !!message.chargesCount +
      !!message.formsCount +
      !!message.signaturesCount +
      !!message.public +
      (message.unread > 0);

    const isMessageWriter = utils.isMessageWriter({ store });
    const isMessageApprover = utils.isMessageApprover({ store });
    const isMessageOwner =
      !!message.entity &&
      !!store.entity &&
      store.entity.id === message.entity.id;

    if (to) {
      params.as = Link;
      params.to = to;
    }

    const validateReviewStatus = () => {
      const status = message?.review?.reviewStatus;

      switch (status) {
        case 'approved':
          return true;
        case 'rejected':
          return true;
        default:
          return false;
      }
    };

    return (
      <List.Item
        className={'messageItem ' + className}
        style={{
          display: 'flex',
          padding: 0,
          borderRadius: 0,
          cursor: 'inherit',
        }}
        title={message.summary}
        data-id={message.id}
      >
        {onToggle && !this.isMobile() ? this.renderToogleMessage() : null}

        <List.Content
          className={'MessageItem'}
          style={
            message.deleted
              ? {
                display: 'flex',
                padding: 16,
                alignItems: 'center',
                width: '100%',
                backgroundColor: message.selected ? '#e8f7ff' : undefined,
                opacity: 0.65,
              }
              : {
                display: 'flex',
                flex: 1,
                padding: 16,
                alignItems: 'center',
                width: '100%',
                backgroundColor: message.selected ? '#e8f7ff' : undefined,
              }
          }
          verticalAlign="middle"
          onClick={onClick}
          {...params}
        >
          {message.type === 'SENT' && !organization ? (
            <div
              style={{
                width: this.isMobile() ? '60px' : '48px',
                height: '48px',
                position: 'relative',
                marginBottom: 3,
              }}
            >
              <Avatar
                avatar
                src={
                  message.toEntity &&
                  message.toEntity.picture &&
                  message.toEntity.picture.uri
                }
                alt={message.toEntity && message.toEntity.fullname}
                style={
                  message.deleted
                    ? message.recipientsCount > 1
                      ? { width: '36px', height: '36px', opacity: 0.65 }
                      : { width: '48px', height: '48px', opacity: 0.65 }
                    : message.recipientsCount > 1
                      ? { width: '36px', height: '36px' }
                      : { width: '48px', height: '48px' }
                }
              />
              {message.recipientsCount > 1 ? (
                <div style={styles.moreRecipients}>
                  +{message.recipientsCount - 1}
                </div>
              ) : null}
              {message.unread !== null ? (
                <div style={styles.corner}>
                  <Icon
                    name="circle"
                    style={{ margin: 0, color: '#0080ff' }}
                    className="bold"
                  />
                </div>
              ) : message.unread === null &&
                message.notifications &&
                message.notifications.totalCount > 0 ? (
                  <div style={styles.corner}>
                    <Icon
                      name="circle"
                      style={{ margin: 0, color: '#0080ff' }}
                      className="bold"
                    />
                  </div>
                ) : null}
            </div>
          ) : (
            <div
              style={{
                width: this.isMobile() ? '60px' : '48px',
                height: '48px',
                position: 'relative',
                marginBottom: 3,
              }}
            >
              <Avatar
                avatar
                src={
                  message.entity &&
                  message.entity.picture &&
                  message.entity.picture.uri
                }
                alt={message.entity.fullname}
                style={
                  message.deleted
                    ? { width: '100%', height: '100%', opacity: 0.65 }
                    : { width: '100%', height: '100%' }
                }
              />
              {message.unread !== null && !organization ? (
                <div style={styles.corner}>
                  <Icon
                    name="circle"
                    style={{ margin: 0, color: '#0080ff' }}
                    className="bold"
                  />
                </div>
              ) : message.unread === null &&
                message.notifications &&
                message.notifications.totalCount > 0 &&
                !organization ? (
                  <div style={styles.corner}>
                    <Icon
                      name="circle"
                      style={{ margin: 0, color: '#0080ff' }}
                      className="bold"
                    />
                  </div>
                ) : null}
            </div>
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: '16px',
            }}
          >
            <div
              className="horizontallySpacedItems"
              style={{ marginBottom: '8px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {message.fwMessageId && (
                  <Icon
                    data-name="forward-name"
                    name="share"
                    style={{
                      color: '#9696a0',
                      marginBottom: 5,
                      opacity: message.deleted ? 0.65 : null,
                    }}
                  />
                )}
                <Header
                  className={'MessageItem'}
                  as="h3"
                  style={{
                    fontSize: 'larger',
                    fontWeight: message.unread === null ? 'normal' : 'bold',
                    opacity: message.deleted ? 0.65 : null,
                    fontStyle: message.deleted ? 'italic' : null,
                  }}
                >
                  {this.renderMessageTitle(message.summary, search)}
                </Header>
                {validateReviewStatus() &&
                  ((isMessageWriter && isMessageOwner) ||
                    isMessageApprover) && (
                  <ReviewedBy
                    entity={message?.review?.user?.fullname}
                    status={message?.review?.reviewStatus}
                  />
                )}
              </div>
              <div style={{ display: 'flex' }}>
                {!!message.label && (
                  <Label
                    data-name="label"
                    size="mini"
                    style={{
                      color: 'white',
                      backgroundColor: `#${message.label.color}`,
                      marginRight: '8px',
                    }}
                  >
                    {message.label.title}
                  </Label>
                )}
                <Header
                  className={'MessageItem'}
                  style={{
                    margin: 0,
                    opacity: message.deleted ? 0.65 : null,
                    fontStyle: message.deleted ? 'italic' : null,
                  }}
                  size="tiny"
                  disabled
                >
                  {date}
                </Header>
              </div>
            </div>
            {isApproval && (
              <div className="horizontallySpacedItems">
                <p className="message-content">
                  {utils.stripHtml(message.content)}
                </p>
              </div>
            )}
            <div
              className={`horizontallySpacedItems sub-header${statusIcons < 6 ? ' nowrap' : ''}`}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginTop: 8,
                }}
              >
                {!deleted
                  ? (message.type === 'SENT' && !organization) ||
                    isApproval ||
                    isMessageToApprove
                    ? this.renderSent()
                    : this.renderReceived()
                  : this.renderDeletedFor()}
              </div>
              <div>{this.renderInfo()}</div>
            </div>
          </div>
        </List.Content>
        {isApproval && (
          <ApproveItem
            message={message}
            handleApprove={handleApprove}
            handleReprove={handleReprove}
            goToEdit={goToEdit}
          />
        )}
      </List.Item>
    );
  }
}
